import React from "react"
import styled from "styled-components"
import { Section } from "../Section"
import { PhoneIcon, HomeIcon } from "../Icons"
import PrimaryButton from "../Button/PrimaryButton"
import BreakpointUp from "../Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"

const CallGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
  margin: 0 auto;
  ${BreakpointUp.xl` 
        max-width: 1920px;
        width:100%;
    `}
`
const CallGridCol = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 80px 15px;
  min-height: 450px;
  ${BreakpointUp.md`
        flex: 0 0 50%;
        max-width: 50%;
        padding:100px 15px;
        min-height:470px;
    `}
  ${BreakpointUp.lg`
        padding:160px 15px;
        min-height:650px;
    `}
    ${BreakpointUp.xl`
        min-height:800px;
    `}
`
const ButtonToolbar = styled.div`
  .btn {
    &:hover {
      border-color: #fff;
    }
  }
`
const CallTitle = styled.h2`
  color: #fff;
  position: relative;
  z-index: 1;
  max-width: 620px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 auto 20px;
  ${BreakpointUp.sm`
    font-size: 18px;
    line-height: 28px;
  `}
  ${BreakpointUp.lg`
    font-weight: 900;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin:0 auto 40px;
  `}
  &:before {
    content: attr(data-shadowtext);
    position: absolute;
    bottom: 100%;
    color: rgba(255, 255, 255, 0.25);
    font-weight: 900;
    left: 0;
    width: 100%;
    font-size: 32px;
    line-height: 40px;
    ${BreakpointUp.sm`
      font-size: 36px;
      line-height: 44px;
    `}
    ${BreakpointUp.md`
      font-size: 40px;
      line-height: 48px;
    `}
    ${BreakpointUp.lg`     
      font-size: 60px;
      line-height: 72px;
    `}
  }
`

const CallBgImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const CallDesignBuilding = () => {
  return (
    <Section pt="0" pb="0" xpt="0" xpb="0" bgColor="#F2F4F9">
      <CallGrid>
        <CallGridCol>
          <CallBgImage>
            <StaticImage src="../../images/callus-bg.jpg" alt="callus" />
          </CallBgImage>
          <CallTitle data-shadowtext="Call Us Now">
            If You Need Any Help, Call Us Now To Discuss More With Our Building
            Expert!{" "}
          </CallTitle>
          <ButtonToolbar>
            <a href="tel:8666817846" aria-label="(866) 681-7846">
              <PrimaryButton icon={<PhoneIcon />} text="(866) 681-7846" />
            </a>
          </ButtonToolbar>
        </CallGridCol>
        <CallGridCol>
          <CallBgImage>
            <StaticImage
              src="../../images/design-building.jpg"
              alt="Design Building"
            />
          </CallBgImage>
          <CallTitle data-shadowtext="Design Building">
            Need Own Design? Design Your Building According To Your Requirement!
          </CallTitle>
          <ButtonToolbar>
            <a
              href="https://coasttocoastcarports.sensei3d.com/"
              target="_blank"
              rel="noreferrer"
              aria-label="3D Design Tool"
            >
              <PrimaryButton icon={<HomeIcon />} text="3D Design Tool" />
            </a>
          </ButtonToolbar>
        </CallGridCol>
      </CallGrid>
    </Section>
  )
}

export default CallDesignBuilding
