import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import {
  Section,
  SectionTitle,
  SectionPageTitle,
  SectionLead,
  BreadCrumb,
} from "../components/Section"
import { ArrowRightIcon } from "../components/Icons"
import PrimaryButton from "../components/Button/PrimaryButton"
import CallDesignBuilding from "../components/CallDesignBuilding"
import BreakpointUp from "../components/Media/BreakpointUp"
import ReviewWidget from "../components/ReviewWidget"

const LeaveBgImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  opacity: 0.1;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const BannerBgImage = styled.div`
  display: none;
  ${BreakpointUp.sm`  
        display:block;
        position: absolute;
        right: 0;
        height: 100%;
        width: 20%;
        top: 70px;
    `}
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`


const ListMap = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
  position: relative;
  z-index: 1;
`
const ListItemMap = styled.div`
  position:relative;
  text-align:center;
  width:100%;
  padding:0 15px;
  margin-bottom:40px;
  @media(min-width:576px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(min-width:768px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`
const MapTitle = styled.div`
  font-size:24px;
  font-weight:34px;
  font-weight:700;
  text-align:center;
  margin: 0 0 15px;
  color:#000;
`
const MapFigure = styled.div`
  margin: 0 0 20px;
  border:2px solid #000;
`
const MapAction = styled.div`
  .btn{
    .icon{
      svg{
        width: 18px;
        height: 16px;
      }
    }
  }
`
const Reviews = ({ location }) => (
  <Layout location={location}>
    <Seo title="Customer Reviews and Ratings | Coast To Coast Carports" description="Looking for carport and metal building reviews and ratings? Read our testimonials to see all the value we bring you. Contact us today to get started." />
    <BreadCrumb>
      <div className="container">
        <Link to="/">Home / </Link>
        <Link to="/about-us">About Us / </Link>
        <span>Reviews</span>
      </div>
    </BreadCrumb>
    <Section
      pt="215px"
      pb="60px"
      xpt="120px"
      xpb="30px"
      bgColor="#F2F4F9"
      className="circle-left sm"
    >
      <BannerBgImage>
        <StaticImage src="../images/hand.png" alt="hand" />
      </BannerBgImage>
      <div className="container">
        <SectionPageTitle textAlign="left" maxWidth="1120px" ml="0">
          See What Our Customers Are Saying About Their Experiences With Coast
          To Coast…
        </SectionPageTitle>
        <SectionLead textAlign="left" maxWidth="1000px" ml="0">
          We want to know how you feel about our company. Share your own story
          with us today. We look forward to learning what you love about our
          business!
        </SectionLead>
      </div>
    </Section>
    <Section pt="0" pb="0" bgColor="#fff">
      <div className="container">
        <ReviewWidget />
      </div>
    </Section>
    <Section
      pt="130px"
      pb="120px"
      xpt="60px"
      xpb="60px"
      bgColor="#F2F4F9"
      className="circle-right md"
    >
      <LeaveBgImage><StaticImage src="../images/leave-review.jpg" quality={90} alt="leave-review" /></LeaveBgImage>
      <div className="container">
        <SectionTitle maxWidth="725px" mb="50px">Happy With Our Services! Please Leave Us A Review</SectionTitle>
        <ListMap>
          <ListItemMap>            
            <MapTitle>Knoxville, AR</MapTitle>
            <MapFigure><StaticImage src="../images/map-location-knoxville.jpg" quality={90} alt="map-location-knoxville" /></MapFigure>
            <MapAction className="btn-sm"><a href="https://g.page/r/CaCFTy494XU6EAI/review" target="_blank" rel="noreferrer" aria-label="Review Us"><PrimaryButton icon={<ArrowRightIcon />} textBefore="Review Us" /></a></MapAction>
          </ListItemMap>
          
          <ListItemMap>            
            <MapTitle>Stanfield, OR</MapTitle>
            <MapFigure><StaticImage src="../images/map-location-stanfield.jpg" quality={90} alt="map-location-stanfield" /></MapFigure>
            <MapAction className="btn-sm"><a href="https://g.page/r/CVxUpK0V_1CfEAI/review" target="_blank" rel="noreferrer" aria-label="Review Us"><PrimaryButton icon={<ArrowRightIcon />} textBefore="Review Us" /></a></MapAction>
          </ListItemMap>
          
          <ListItemMap>            
            <MapTitle>Amarillo, TX</MapTitle>
            <MapFigure><StaticImage src="../images/map-location-amarillo.jpg" quality={90} alt="map-location-amarillo" /></MapFigure>
            <MapAction className="btn-sm"><a href="https://g.page/r/CXpAqiPfZ2bcEAI/review" target="_blank" rel="noreferrer" aria-label="Review Us"><PrimaryButton icon={<ArrowRightIcon />} textBefore="Review Us" /></a></MapAction>
          </ListItemMap>

          <ListItemMap>            
            <MapTitle>Heyburn, ID</MapTitle>
            <MapFigure><StaticImage src="../images/map-location-heyburn.jpg" quality={90} alt="map-location-heyburn" /></MapFigure>
            <MapAction className="btn-sm"><a href="https://g.page/r/CbQbqR-BK2npEAI/review" target="_blank" rel="noreferrer" aria-label="Review Us"><PrimaryButton icon={<ArrowRightIcon />} textBefore="Review Us" /></a></MapAction>
          </ListItemMap>

          <ListItemMap>            
            <MapTitle>Jacksonville, IL</MapTitle>
            <MapFigure><StaticImage src="../images/map-location-jacksonville.jpg" quality={90} alt="map-location-jacksonville" /></MapFigure>
            <MapAction className="btn-sm"><a href="https://g.page/r/CRTbr-rER01xEAI/review" target="_blank" rel="noreferrer" aria-label="Review Us"><PrimaryButton icon={<ArrowRightIcon />} textBefore="Review Us" /></a></MapAction>
          </ListItemMap>

          <ListItemMap>            
            <MapTitle>Smithville, TN</MapTitle>
            <MapFigure><StaticImage src="../images/map-location-smithville.jpg" quality={90} alt="map-location-smithville" /></MapFigure>
            <MapAction className="btn-sm"><a href="https://g.page/r/CTgU9AWZGMOPEAI/review" target="_blank" rel="noreferrer" aria-label="Review Us"><PrimaryButton icon={<ArrowRightIcon />} textBefore="Review Us" /></a></MapAction>
          </ListItemMap>
        </ListMap>
      </div>
    </Section>
    <CallDesignBuilding />
  </Layout>
)

export default Reviews

export const pageQuery = graphql`
  query ReviewsQuery {
    allContentfulReview {
      edges {
        node {
          reviewText {
            childMarkdownRemark {
              html
            }
          }
          customerName
          state {
            name
            abbreviation
            url
            flag {
              title
              gatsbyImageData
            }
          }
          linkedProduct {
            url
            productName
            productImages {
              title
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`